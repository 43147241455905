import React, { useEffect } from "react";
import "./AccountForm.css";
import { useForm } from "react-hook-form";

import { Button, Col, Form, FormGroup, Input, Row } from "reactstrap";

import { LabelWithToolTip } from "../../atoms/LabelWithToolTip";
import { isNullOrEmpty, setMaxLength } from "../../../utils/constants";

export const AccountForm = (props) => {
  //const [accountEditMode, setAccountEditMode] = useState(false);
  // const [formData, setFormData] = useState({});

  const { register, handleSubmit, errors, setValue } = useForm({
    defaultValues: {
      // name: props.account.name,
      // bic: "NDEADKKK",
      isIBAN: true,
    },
  });

  useEffect(() => {
    const paymentTermId =
      props.paymentTermList.find(
        (term) =>
          term.displayName === props.account.paymentTerm ||
          term.id === props.account.paymentTerm
      )?.id ?? props.paymentTermList.find((x) => x.isDefault === 1).id;
    setValue("name", props.account.name);
    setValue("address1", props.account.address1);
    setValue("address2", props.account.address2);
    setValue("city", props.account.city);
    setValue("zip", props.account.zip);
    setValue("countryId", props.account.countryId);
    setValue("phone", props.account.phone);
    setValue("salesEmail", props.account.salesEmail);
    setValue("financeEmail", props.account.financeEmail);
    setValue("taxRegistrationNumber", props.account.taxRegistrationNumber);
    setValue("paymentTerm", paymentTermId);
    setValue("contactName", props.account.contactName);
    setValue("contactMobile", props.account.contactMobile);
    setValue("notes", props.account.notes);
  }, [props.account]);

  // account.name = updatedAccount.name;
  // account.address1 = updatedAccount.address1;
  // account.address2 = updatedAccount.address2;
  // account.address3 = updatedAccount.address3;
  // account.address4 = updatedAccount.address4;
  // account.city = updatedAccount.city;
  // account.zip = updatedAccount.zip;
  // account.state = updatedAccount.state;
  // account.countryId = parseInt(updatedAccount.countryId, 10);
  // account.phone = updatedAccount.phone;
  // account.fax = updatedAccount.fax;
  // account.email = updatedAccount.email;
  // account.postbox = updatedAccount.postbox;

  const onSubmit = async (account) => {
    var country = props.countryList.find(
      (country) => country.id === Number(account.countryId)
    );
    account.country = country.country;
    if (!isNullOrEmpty(account.taxRegistrationNumber)) {
      account.taxRegistrationNumber = account.taxRegistrationNumber.replace(
        /\s+/g,
        ""
      );
    }
    if (!isNullOrEmpty(account.paymentTerm)) {
      account.paymentTermName = props.paymentTermList
        .find((term) => term.id.toString() === account.paymentTerm)
        ?.displayName.replace(/\s+/g, "");
    }
    // console.log("===================>>>>>>>>>>>>", account);
    props.onSubmit(account);
  };

  const onChange = () => {
    props.onChange();
  };

  const handleOnChangeTaxRegistrationNumber = (e) => {
    e.target.value = e.target.value.replace(/\s+/g, "");
    e.target.value = setMaxLength(20, e.target.value);
    onChange();
  };

  const hasPoolPartner = () => props.accountTypes?.includes("Pool Partner");
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col sm="12" md="4">
          <FormGroup>
            <LabelWithToolTip
              id="labelVendorName"
              labelText="Vendor Name*"
              tooltipText="Please make sure that the name filled in is same as Bank Account name. If the names are different, please input reason under 'Notes'."
            />

            <Input
              maxLength="100"
              innerRef={register({ required: true })}
              type="input"
              name="name"
              onChange={onchange}
              //disabled={!accountEditMode}
            />
            {errors.vendorName && <div className={"text-danger"}>Required</div>}
          </FormGroup>
        </Col>
        <Col sm="12" md="4">
          <FormGroup>
            <LabelWithToolTip
              id="labelAddress1"
              labelText="Address Line 1*"
              tooltipText="Please input the 1st line of your company's address (Max 50 characters)"
            />

            <Input
              maxLength="50"
              innerRef={register({ required: true })}
              type="input"
              name="address1"
              onChange={onChange}
              // disabled={!accountEditMode}
            />
          </FormGroup>
        </Col>
        <Col sm="12" md="4">
          <FormGroup>
            <LabelWithToolTip
              id="labelAddress2"
              labelText="Address Line 2"
              tooltipText="Please input the 2nd line of your company's address (Max 50 characters)"
            />

            <Input
              maxLength="50"
              innerRef={register()}
              type="input"
              name="address2"
              onChange={onChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm="12" md="4">
          <FormGroup>
            <LabelWithToolTip
              id="labelCity"
              labelText="City*"
              tooltipText="Please input the city in which the Registered Address is located"
            />

            <Input
              innerRef={register({ required: true })}
              type="input"
              name="city"
              onChange={onChange}
              // disabled={!accountEditMode}
              maxLength="30"
            />
          </FormGroup>
        </Col>

        <Col sm="12" md="4">
          <FormGroup>
            <LabelWithToolTip
              id="labelZip"
              labelText="Zip/Postal*"
              tooltipText="Please input the Postal Code of the Registered Address"
            />

            <Input
              innerRef={register({ required: true })}
              type="zip"
              name="zip"
              onChange={onChange}
              //disabled={!accountEditMode}
            />
          </FormGroup>
        </Col>
        <Col sm="12" md="4">
          <FormGroup>
            <LabelWithToolTip
              id="labelCountry"
              labelText="Country*"
              tooltipText="Please select country of the Registered Address from drop down list"
            />

            <select
              ref={register({ required: true })}
              className="form-control"
              name="countryId"
              defaultValue={props.account.countryId}
              onChange={onChange}
              // onChange={e => setCountry(e.nativeEvent.target.text)}
              // disabled={!accountEditMode}
            >
              {props.countryList.map((country, index) => (
                <option key={country.id} value={country.id}>
                  {country.country}
                </option>
              ))}
            </select>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col sm="12" md="4">
          <FormGroup>
            <LabelWithToolTip
              id="labelPhone"
              labelText="Phone*"
              tooltipText="Please input Finance Department Contact Number "
            />

            <Input
              innerRef={register({ required: true })}
              type="tel"
              name="phone"
              onChange={onChange}
              // disabled={!accountEditMode}
            />
          </FormGroup>
        </Col>
        <Col sm="12" md={hasPoolPartner() ? "8" : "4"}>
          <FormGroup>
            <LabelWithToolTip
              id="labelTax"
              labelText="Tax registration number"
              tooltipText="Please input Tax Registration Number (Numbers must be without spaces and must not exceed 20 characters). If company is not tax registered, leave this blank."
            />

            <Input
              innerRef={register()}
              type="input"
              name="taxRegistrationNumber"
              onChange={handleOnChangeTaxRegistrationNumber}
              // disabled={!accountEditMode}
            />
          </FormGroup>
        </Col>

        {!hasPoolPartner() && (
          <Col sm="12" md="4">
            <FormGroup>
              <LabelWithToolTip
                id="labelPayment"
                labelText="Payment terms in days"
                tooltipText="Please select payment terms from dropdown list. Default payment is 30 days."
              />

              <select
                ref={register}
                className="form-control"
                name="paymentTerm"
                onChange={onChange}
              >
                {props.paymentTermList.map((term, index) => (
                  <option key={term.id} value={term.id}>
                    {term.displayName}
                  </option>
                ))}
              </select>
            </FormGroup>
          </Col>
        )}
      </Row>

      <Row>
        <Col sm="12" md="4">
          <FormGroup>
            <LabelWithToolTip
              id="labelFinanceEmail"
              labelText="Finance email address"
              tooltipText="Please input the email address used for remittance correspondence"
            />

            <Input
              innerRef={register()}
              type="email"
              name="financeEmail"
              onChange={onChange}
              // disabled={!accountEditMode}
            />
          </FormGroup>
        </Col>
        <Col sm="12" md="4">
          <FormGroup>
            <LabelWithToolTip
              id="labelSalesEmail"
              labelText="Sales email address"
              tooltipText="Please input the email address used for sales correspondencee"
            />

            <Input
              innerRef={register()}
              type="email"
              name="salesEmail"
              onChange={onChange}
              // disabled={!accountEditMode}
            />
          </FormGroup>
        </Col>
        <Col sm="12" md="4">
          <FormGroup>
            <LabelWithToolTip
              id="labelContactName"
              labelText="Sales contact name"
              tooltipText="Please input the name of the Sales Contact"
            />

            <Input
              onChange={onChange}
              innerRef={register()}
              type="input"
              name="salesContactName"
              //  disabled={!accountEditMode}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm="12" md="4">
          <FormGroup>
            <LabelWithToolTip
              id="labelContactPhone"
              labelText="Sales contact phone/mobile"
              tooltipText="Please input the contact number of the Sales Contact"
            />

            <Input
              onChange={onChange}
              innerRef={register()}
              type="tel"
              name="salesContactMobile"
              //  disabled={!accountEditMode}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col sm="12" md="12">
          <FormGroup>
            <LabelWithToolTip
              id="labelNotes"
              labelText="Notes"
              tooltipText="State reason for difference in Vendor Name and Bank Account Name (if any)"
            />

            <Input
              onChange={onChange}
              innerRef={register()}
              type="textarea"
              name="notes"
              // disabled={!accountEditMode}
            />
          </FormGroup>
        </Col>
      </Row>

      <br />
      {/* {accountEditMode && ( */}
      <Button color="success" type="submit">
        Save
      </Button>
      {/* )} */}
      {/* {!accountEditMode && (
        <Button
          color="info"
          onClick={() => setAccountEditMode(!accountEditMode)}
        >
          Edit
        </Button>
      )} */}
    </Form>
  );
};
