import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Tooltip from "react-tooltip-lite";
import {
  apiBankDataSetPrimary,
  apiCompleteAccount,
  apiDeleteBankData,
  apiDeleteFile,
  apiGetAccount,
  apiPostBankData,
  apiUpdateAccount,
  apiUpdateGiro,
} from "../../../utils/queries";
import Confirm from "../../molecules/Confirm";
import "./HomePage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faFileUpload,
  faInfoCircle,
  faList,
  faPlus,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";

import { default as UUID } from "uuid";

import { BankDataEditModal } from "./BankDataEditModal";
import { FilesForm } from "./FilesForm";
import { BankDataViewModal } from "./BankDataViewModal";
import { FilesModal } from "./FilesModal";
import { AccountForm } from "./AccountForm";
import { PolicyModal } from "./PolicyModal";

import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Label,
  Form,
  FormGroup,
  Input,
  Row,
  Table,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { isNullOrEmpty } from "../../../utils/constants";

const AlertType = {
  Account: 1,
  BankDetails: 2,
  Files: 3,
};

const Home = (props) => {
  const navigate = useNavigate();
  const [alerts, setAlerts] = useState([]);

  const [selectedBankData, setSelectedBankData] = useState({});
  const [bankDataList, setBankDataList] = useState([]);

  const [selectedFile, setSelectedFile] = useState({});

  const [accountLoaded, setAccountLoaded] = useState(false);
  const [formConfirmed, setFormConfirmed] = useState(false);
  const [formSOCConfirmed, setFormSOCConfirmed] = useState(false);

  const [toggleConfirmFileDelete, setToggleConfirmFileDelete] = useState(false);
  const [togglePolicyModal, setTogglePolicyModal] = useState(false);

  const [account, setAccount] = useState({});
  const [accountTypes, setAccountTypes] = useState();

  const [fileList, setFileList] = useState([]);
  const [countryList, setCountryList] = useState([]);

  const [fullCurrencyList, setFullCurrencyList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);

  const [paymentTermList, setPaymentTermList] = useState([]);

  const [accountHasChanged, setAccountHasChanged] = useState(false);
  const [isFinanceAccount, setIsFinanceAccount] = useState(false);

  const [showAccountSaveError, setShowAccountSaveError] = useState(false);
  const [showAccountChangedError, setShowAccountChangedError] = useState(false);
  const [showAccountFileRequiredError, setShowAccountFileRequiredError] =
    useState(false);

  const [isGiro, setIsGiro] = useState(null);

  let { token } = useParams();

  useEffect(() => {
    // appInsights.trackTrace({
    //   message: "virker",
    //   severityLevel: SeverityLevel.Information,
    //   properties: { service: "vendorform.portal", token: token },
    // });

    async function getAccount() {
      try {
        let account = await apiGetAccount(token);

        if (account.data.account.formSubmitted === true) {
          navigate("/complete");
          return;
        }

        setIsGiro(account.data.account.isGiro);
        setAccount(account.data.account);
        setAccountTypes(account.data.accountTypes);

        setBankDataList(account.data.bankDataList);
        setFileList(account.data.files);
        setCountryList(account.data.countryList);
        setPaymentTermList(account.data.paymentTermList);
        setFullCurrencyList(account.data.currencyList);
        setIsFinanceAccount(account.data.isFinanceAccount);

        // let usedCurrencyList = account.data.bankDataList.map(p=>p.currencyId);
        // let currencyList = account.data.currencyList.filter(p=>!usedCurrencyList.includes(p.id));
        // setCurrencyList(currencyList);

        updateCurrencyList(
          account.data.bankDataList,
          account.data.currencyList
        );

        setAccountLoaded(true);
      } catch (error) {
        // console.log(error);
        // console.log("ACCOUNTS", "EC154425-0580-4D7E-8B6B-E0E172F95201");
        // navigate(`/tokenfail`);
      }
    }

    getAccount();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    updateCurrencyList(bankDataList, fullCurrencyList);

    // eslint-disable-next-line
  }, [bankDataList]);

  const updateCurrencyList = (bankDataList, fullCurrencyList) => {
    let usedCurrencyList = bankDataList.map((p) => p.currencyId);
    // let currencyList = fullCurrencyList.filter(
    //   (p) => !usedCurrencyList.includes(p.id)
    // );
    setCurrencyList(fullCurrencyList);
  };

  const onGiroSave = async (giro) => {
    try {
      await apiUpdateAccount(account, token);
    } catch (error) {
      return false;
    }
    return true;
  };

  const addAlert = (message, alertType, color) => {
    let id = UUID.v4();
    setAlerts([
      ...alerts,
      { message: message, id: id, alertType: alertType, color: color },
    ]);
  };

  // const uploadFiles = async (e) => {
  //   // alert(token);
  //   //   let form = new FormData();
  //   //   form.append("token", token);
  //   //   for (var index = 0; index < files.length; index++) {
  //   //     // var element = files[index];
  //   //     form.append("file", element);
  //   //   }
  //   //   try{
  //   //     let files = await apiPostFiles(form);
  //   //     addAlert(`File was uploaded`, AlertType.Files,"success");
  //   //   }catch(error){
  //   //     addAlert(`Uploading file failed. Please contact Hafnia: ${error}`, AlertType.Files,"danger");
  //   //   }
  //   // axios
  //   //   .post("https://localhost:44371/api/files", form, "sdfdsf")
  //   //   .then((result) => {
  //   //     console.log(result);
  //   //   })
  //   //   .catch((ex) => {
  //   //     console.error(ex);
  //   //   });
  //   // alert("Upload filesS");
  // };

  // const selectedFilesChanged = async (e) => {
  //   let form = new FormData();

  //   for (var index = 0; index < e.target.files.length; index++) {
  //     var file = e.target.files[index];
  //     form.append("file", file);
  //   }

  //   try {
  //     let files = await trackPromise(apiPostFiles(form, token));

  //     console.log(files);

  //     setFileList([...fileList, ...files.data]);
  //     console.log(fileList);

  //     addAlert(`Files was uploaded`, AlertType.Files, "success");
  //   } catch (error) {
  //     addAlert(
  //       `Uploading file failed. Please contact Hafnia: ${error}`,
  //       AlertType.Files,
  //       "danger"
  //     );
  //   }

  //   //var filesArr = Array.prototype.slice.call(e.target.files);
  //   //setFiles(filesArr);
  //   //console.log(filesArr);
  //   // console.log(e.target.files);
  //   //alert(e);
  // };

  // const onDismissAlert = (a) => {
  //   setAlerts(alerts.filter((item) => item.id !== a.id));
  // };

  const onDeleteFileConfirmed = async () => {
    // console.log("Delete");
    // console.log(selectedBankData);
    try {
      await apiDeleteFile(token, selectedFile.name);
      addAlert("File deleted", AlertType.Files, "success");
      setFileList(fileList.filter((item) => item.name !== selectedFile.name));
    } catch (error) {
      addAlert(
        "Could not delete file. Please contact Hafnia.",
        AlertType.Files,
        "danger"
      );
    }
  };

  // const onDeleteBankDataConfirmed = async () => {
  //   console.log("Delete");
  //   console.log(selectedBankData);
  //   try {
  //     await apiDeleteBankData(selectedBankData.token, selectedBankData.id);
  //     addAlert("Bank data deleted", AlertType.BankDetails, "success");
  //     setBankDataList(
  //       bankDataList.filter((item) => item.id !== selectedBankData.id)
  //     );
  //   } catch (error) {
  //     addAlert(
  //       "Could not delete bank data. Please contact Hafnia.",
  //       AlertType.BankDetails,
  //       "danger"
  //     );
  //   }
  // };

  // const onDeleteBankData = async (bankData) => {
  //   setSelectedBankData(bankData);
  //   setToggleConfirmDelete(!toggleConfirmDelete);
  // };

  // const onDeleteFile = async (file) => {
  //   setSelectedFile(file);
  //   setToggleConfirmFileDelete(!toggleConfirmFileDelete);
  // };

  useEffect(() => {
    setShowAccountFileRequiredError(false);
  }, [fileList]);

  const completeForm = async () => {
    if (!account.isSaved) {
      setShowAccountSaveError(true);
      return;
    }

    if (accountHasChanged) {
      setShowAccountChangedError(true);
      return;
    }

    if (fileList.length === 0) {
      setShowAccountFileRequiredError(true);
      return;
    }

    try {
      await apiCompleteAccount(token);
      navigate(`/complete`);
    } catch (error) {}
  };

  const setBankType = async (isGiro) => {
    try {
      await apiUpdateGiro(isGiro, token);
      setIsGiro(isGiro);
    } catch (error) {}
  };

  const BankInit = () => {
    return (
      <>
        <Row>
          <Col>
            <Button
              color="info"
              type="button"
              className={"bankButtons"}
              onClick={() => setBankType(false)}
            >
              BIC/SWIFT/IBAN
            </Button>
          </Col>

          <Col>
            <Button
              color="info"
              type="button"
              className={"bankButtons"}
              onClick={() => setBankType(true)}
            >
              GIRO
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  const onAccountSave = async (updatedAccount) => {
    try {
      account.name = updatedAccount.name;
      account.address1 = updatedAccount.address1;
      account.address2 = updatedAccount.address2;
      account.address3 = updatedAccount.address3;
      account.address4 = updatedAccount.address4;
      account.city = updatedAccount.city;
      account.zip = updatedAccount.zip;
      account.state = updatedAccount.state;
      account.countryId = parseInt(updatedAccount.countryId, 10);
      account.phone = updatedAccount.phone;
      account.fax = updatedAccount.fax;
      account.salesEmail = updatedAccount.salesEmail;
      account.financeEmail = updatedAccount.financeEmail;
      account.postbox = updatedAccount.postbox;
      account.salesContactName = updatedAccount.salesContactName;
      account.salesContactMobile = updatedAccount.salesContactMobile;
      account.paymentTerm = parseInt(updatedAccount?.paymentTerm);
      account.paymentTermName = updatedAccount?.paymentTermName;
      account.taxRegistrationNumber = updatedAccount.taxRegistrationNumber;
      account.notes = updatedAccount.notes;
      account.isSaved = true;

      await apiUpdateAccount(account, token);
      setAccountHasChanged(false);
      setShowAccountSaveError(false);
      setShowAccountChangedError(false);
      setShowAccountFileRequiredError(false);
      addAlert(`Account has been updated`, AlertType.Account, "success");
    } catch (error) {
      addAlert(
        "Could not update account. Please contact Hafnia.",
        AlertType.Account,
        "danger"
      );
    }
  };

  const accountFormChanged = () => {
    setAccountHasChanged(true);
  };

  return (
    <Container>
      {/* <div>
        <div>
          <pre>{JSON.stringify(debug, null, 2)}</pre>
        </div>
      </div> */}

      <Row className={"mb-2"}>
        <Col sm={12}>
          <Card>
            <CardHeader>Submit Form To Hafnia</CardHeader>
            <CardBody>
              <div className="alert alert-primary" role="alert">
                <FontAwesomeIcon className={"fa-1x"} icon={faInfoCircle} /> Fill
                in all information in the cards below. When finished click the
                Form completed button in this card.
              </div>

              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    onChange={() => setFormConfirmed(!formConfirmed)}
                  />{" "}
                  Please read our policy before completing form{" "}
                  <a
                    href="#0"
                    onClick={() => setTogglePolicyModal(!togglePolicyModal)}
                  >
                    PERSONAL DATA POLICY (For vendors)
                  </a>
                </Label>
              </FormGroup>

              <FormGroup check className=" my-2">
                <Label check>
                  <Input
                    type="checkbox"
                    onChange={() => setFormSOCConfirmed(!formSOCConfirmed)}
                  />{" "}
                  Declaration of vendors acknowledgement and acceptance of
                  Hafnia’s{" "}
                  <a
                    href="https://hafniabw.com/wp-content/uploads/sites/5/2023/11/Supplier-Code-of-Conduct.pdf"
                    target="_blank"
                  >
                    SUPPLIER CODE OF CONDUCT.
                  </a>
                </Label>
              </FormGroup>
              <Button
                onClick={completeForm}
                color="info"
                disabled={!(formConfirmed && formSOCConfirmed)}
              >
                Press here to submit form to Hafnia
              </Button>

              {showAccountSaveError && (
                <p className="text-danger">
                  Account information needs to be added and saved before
                  completing form.
                </p>
              )}

              {showAccountChangedError && (
                <p className="text-danger">
                  You have unsaved changes in the account form. Please save it
                  before completing the form.
                </p>
              )}
              {showAccountFileRequiredError && (
                <p className="text-danger">
                  Please attach a supporting document for this request (e.g.
                  Supporting bank statement with bank details or letter signed
                  by authorised signatory)
                </p>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row className={"mb-2"}>
        <Col sm={12}>
          <Card>
            <CardHeader>Account</CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <Alerts
                    alertType={AlertType.Account}
                    alerts={alerts}
                    setAlerts={setAlerts}
                  ></Alerts>
                </Col>
              </Row>
              {/* <div class="alert alert-primary" role="alert">
            <FontAwesomeIcon className={"fa-1x"} icon={faInfoCircle} si /> Remember to click save to save any changes to the form.
            </div> */}
              {accountLoaded && (
                <AccountForm
                  onSubmit={onAccountSave}
                  countryList={countryList}
                  paymentTermList={paymentTermList}
                  account={account}
                  onChange={accountFormChanged}
                  accountTypes={accountTypes?.split(", ")}
                ></AccountForm>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row className={"mb-2"}>
        {accountLoaded && (
          <Col sm={12}>
            <Card>
              <CardHeader>Bank Details</CardHeader>
              <CardBody>
                {isGiro === false && (
                  <div className="alert alert-primary" role="alert">
                    <FontAwesomeIcon
                      className={"fa-1x"}
                      icon={faInfoCircle}
                    ></FontAwesomeIcon>{" "}
                    <span>
                      Please input all your payment accounts. Please ensure that
                      there is only one currency for each bank account number.
                    </span>
                  </div>
                )}

                {isGiro === null && (
                  <div className="alert alert-primary" role="alert">
                    <FontAwesomeIcon className={"fa-1x"} icon={faInfoCircle} />{" "}
                    Click on one of the buttons below. Notice this setting
                    cannot be changed after choice has been done.
                  </div>
                )}
                {isGiro === null && <BankInit></BankInit>}
                {isGiro === false && (
                  <BankDetailsTable
                    fileList={fileList}
                    setFileList={setFileList}
                    currencyList={currencyList}
                    bankDataList={bankDataList}
                    setBankDataList={setBankDataList}
                    token={token}
                    accountId={account.id}
                    isFinanceAccount={isFinanceAccount}
                    selectedBankData={selectedBankData}
                    setSelectedBankData={setSelectedBankData}
                  ></BankDetailsTable>
                )}
                {isGiro === true && (
                  <GIRO
                    onGiroSave={onGiroSave}
                    account={account}
                    setAccount={setAccount}
                  ></GIRO>
                )}
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>

      <Row className={"mb-2"}>
        <Col sm={12}>
          <Card>
            <CardHeader>Attach Files</CardHeader>
            <CardBody>
              <div className="alert alert-primary" role="alert">
                <FontAwesomeIcon
                  className={"fa-1x"}
                  icon={faInfoCircle}
                ></FontAwesomeIcon>{" "}
                <span>
                  Please attach a sample invoice or a Bank letter indicating the
                  company name and bank details.
                </span>
              </div>

              <FilesForm
                bankDataId={0}
                accountId={account.id}
                name="mainFileForm"
                fileList={fileList}
                setFileList={setFileList}
                token={token}
              ></FilesForm>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <PolicyModal
        toggle={togglePolicyModal}
        setToggle={setTogglePolicyModal}
      ></PolicyModal>

      <Confirm
        onConfirm={onDeleteFileConfirmed}
        text="Are you sure you want to delete this?"
        toggle={toggleConfirmFileDelete}
        setToggle={setToggleConfirmFileDelete}
      ></Confirm>

      {/* <Confirm
        onConfirm={onDeleteBankDataConfirmed}
        text="Are you sure you want to delete this?"
        toggle={toggleConfirmDelete}
        setToggle={setToggleConfirmDelete}
      ></Confirm> */}
    </Container>
  );
};

const BankDetailsTable = (props) => {
  const [alerts, setAlerts] = useState([]);
  const [toggleBankDataModal, setToggleBankDataModal] = useState(false);
  const [toggleBankDataViewModal, setToggleBankDataViewModal] = useState(false);

  const [toggleFilesModal, setToggleFilesModal] = useState(false);
  const [toggleConfirmDelete, setToggleConfirmDelete] = useState(false);

  const addAlert = (message, color) => {
    let id = UUID.v4();
    setAlerts([...alerts, { message: message, id: id, color: color }]);
  };

  const onSetBankDataPrimary = async (bankData) => {
    try {
      await apiBankDataSetPrimary(bankData.id, props.token);

      props.setBankDataList(
        props.bankDataList.map((item) =>
          item.id === bankData.id
            ? { ...item, isPrimary: true }
            : { ...item, isPrimary: false }
        )
      );

      addAlert("Bank data has been set as primary", "success");
    } catch (error) {
      addAlert(
        "Could not set bank as primary. Please contact Hafnia.",
        "danger"
      );
    }
  };

  const onDeleteBankData = async (bankData) => {
    props.setSelectedBankData(bankData);
    setToggleConfirmDelete(!toggleConfirmDelete);
  };

  const bankDetailsSubmitted = async (bankData) => {
    bankData.token = props.token;
    bankData.accountId = props.accountId;
    bankData.currencyId = parseInt(bankData.currencyId, 10);

    try {
      console.log(bankData);
      bankData = await apiPostBankData(bankData, props.token);
      props.setBankDataList([...props.bankDataList, bankData.data]);
      setToggleBankDataModal(!toggleBankDataModal);
      console.log("BankData");
      console.log(bankData);
      addAlert("Bank data added to report.", "success");
    } catch (error) {
      // if (error.response.status === 417) {
      // }

      addAlert(
        "Bank data could not be added. Please contact Hafnia.",
        "danger"
      );
    }

    console.log("bankDataList");
    console.log(props.bankDataList);
  };

  const onDeleteBankDataConfirmed = async () => {
    console.log("Delete");
    console.log(props.selectedBankData);
    try {
      await apiDeleteBankData(
        props.selectedBankData.token.toUpperCase(),
        props.selectedBankData.id
      );
      addAlert("Bank data deleted", "success");
      props.setBankDataList(
        props.bankDataList.filter(
          (item) => item.id !== props.selectedBankData.id
        )
      );
    } catch (error) {
      addAlert("Could not delete bank data. Please contact Hafnia.", "danger");
    }
  };

  const onViewBankData = (bankData) => {
    props.setSelectedBankData(bankData);
    setToggleBankDataViewModal(!toggleBankDataViewModal);
  };

  const onViewFilesOnBank = (bankData) => {
    props.setSelectedBankData(bankData);
    setToggleFilesModal(!toggleFilesModal);
  };

  return (
    <>
      <Row>
        <Col>
          <Alerts alerts={alerts} setAlerts={setAlerts}></Alerts>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            color="info"
            type="button"
            className={"px-3"}
            onClick={() => setToggleBankDataModal(!toggleBankDataModal)}
          >
            <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table borderless striped>
            <colgroup>
              <col span="1" style={{ width: "auto" }} />
              <col span="1" style={{ width: "auto" }} />
              <col span="1" style={{ width: "auto" }} />
              <col span="1" style={{ width: "auto" }} />
              <col span="1" style={{ width: "10%" }} />
            </colgroup>
            <thead>
              <tr>
                <th>Type</th>
                <th className={"d-none d-sm-table-cell"}>CURRENCY</th>
                <th className={"d-none d-sm-table-cell"}>BANK NAME</th>
                <th className={"d-none d-sm-table-cell"}>COUNTRY</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {props.bankDataList?.map((bank, index) => (
                <tr key={index}>
                  <td>{bank.type}</td>

                  <td className={"d-none d-sm-table-cell"}>
                    {bank.currency?.name}
                  </td>
                  <td className={"d-none d-sm-table-cell"}>
                    {isNullOrEmpty(bank.bank) ? "N/A" : bank.bank}
                  </td>
                  <td className={"d-none d-sm-table-cell"}>
                    {isNullOrEmpty(bank.country) ? "N/A" : bank.country}
                  </td>
                  <td className={"text-right"}>
                    <Tooltip
                      direction="up-end"
                      content="Set bank data as primary"
                      className="d-inline"
                    >
                      <FontAwesomeIcon
                        color={bank.isPrimary ? "green" : "lightgrey"}
                        icon={faCheck}
                        onClick={() => onSetBankDataPrimary(bank)}
                        className="mr-2"
                      />
                    </Tooltip>

                    <Tooltip
                      direction="up-end"
                      content="Show files"
                      className="d-inline"
                    >
                      <FontAwesomeIcon
                        icon={faFileUpload}
                        onClick={() => onViewFilesOnBank(bank)}
                        className="mr-2"
                      />
                    </Tooltip>
                    <Tooltip
                      direction="up-end"
                      content="Show details"
                      className="d-inline"
                    >
                      <FontAwesomeIcon
                        icon={faList}
                        onClick={() => onViewBankData(bank)}
                        className="mr-2"
                      />
                    </Tooltip>
                    <Tooltip
                      direction="up-end"
                      content="Delete bank info"
                      className="d-inline"
                    >
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        onClick={() => onDeleteBankData(bank)}
                      />
                    </Tooltip>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <BankDataEditModal
        onSubmit={bankDetailsSubmitted}
        toggle={toggleBankDataModal}
        setToggle={setToggleBankDataModal}
        token={props.token}
        accountId={props.accountId}
        isGiro={props.account?.giro ? true : false}
        isFinanceAccount={props.isFinanceAccount}
        currencyList={props.currencyList}
        addAlert={addAlert}
      ></BankDataEditModal>

      <BankDataViewModal
        selectedBankData={props.selectedBankData}
        toggle={toggleBankDataViewModal}
        setToggle={setToggleBankDataViewModal}
        token={props.token}
        closeBtn
      ></BankDataViewModal>

      <FilesModal
        accountId={props.accountId}
        bankData={props.selectedBankData}
        toggle={toggleFilesModal}
        setToggle={setToggleFilesModal}
        token={props.token}
        fileList={props.fileList}
        setFileList={props.setFileList}
        closeBtn={true}
      ></FilesModal>

      <Confirm
        onConfirm={onDeleteBankDataConfirmed}
        text="Are you sure you want to delete this?"
        toggle={toggleConfirmDelete}
        setToggle={setToggleConfirmDelete}
      ></Confirm>
    </>
  );
};

const GIRO = (props) => {
  const [giroAlerts, setGiroAlerts] = useState([]);
  const [editMode, setEditMode] = useState(false);

  const addAlert = (message, color) => {
    let id = UUID.v4();
    setGiroAlerts([...giroAlerts, { message: message, id: id, color: color }]);
  };

  const onGiroSave = () => {
    var succeded = props.onGiroSave();

    if (succeded) {
      addAlert("GIRO saved", "success");
      setEditMode(false);
    } else {
      addAlert("Error occured saving giro", "error");
    }

    //alert(succeded);
  };

  const onGiroChanged = (e) => {
    props.setAccount({
      ...props.account,
      giro: e.target.value,
    });
  };

  return (
    <Form>
      <Row>
        <Col>
          <Alerts alerts={giroAlerts} setAlerts={setGiroAlerts}></Alerts>
        </Col>
      </Row>
      <Row>
        <Col sm="12" md="6">
          <FormGroup>
            <Label>GIRO</Label>
            <Input
              value={props.account?.giro}
              onChange={onGiroChanged}
              maxLength="35"
              type="input"
              name="address3"
              disabled={!editMode}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row style={{ marginTop: "10px" }}>
        <Col sm="12" md="4">
          {!editMode && (
            <Button
              color="info"
              onClick={() => {
                setEditMode(true);
              }}
            >
              Edit
            </Button>
          )}
          {editMode && (
            <Button color="success" onClick={onGiroSave}>
              Save
            </Button>
          )}
        </Col>
      </Row>
    </Form>
  );
};

const Alerts = (props) => {
  const onDismissAlert = (a) => {
    props.setAlerts(props.alerts.filter((item) => item.id !== a.id));
  };

  return (
    <>
      {props.alerts
        .filter((alert) => alert.alertType === props.alertType)
        .map((alert, index) => (
          <Alert
            key={index}
            color={alert.color}
            toggle={() => onDismissAlert(alert)}
          >
            {alert.message}
          </Alert>
        ))}
    </>
  );
};

export default Home;
